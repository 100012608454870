/**************************************************
 * Nombre:       Seccion_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Portada_Nosotros from "./Componentes/Portada_Nosotros";
import Historia_Nosotros from "./Componentes/Historia_Nosotros";
import Equipo_Trabajo from "./Componentes/Equipo_Trabajo";
import {useEffect} from "react";
import {irArriba} from "../../Utilidades/irArriba";

const Seccion_Nosotros = () => {

    useEffect(() => {
        irArriba()
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <Portada_Nosotros/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 14}}>
                <Historia_Nosotros/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 12, marginBottom: 12}}>
                <Equipo_Trabajo/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Nosotros