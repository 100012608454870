/**************************************************
 * Nombre:       Nosotros_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import granos from '../../../Recursos/granos.png'
import {useNavigate} from "react-router-dom";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";


const Nosotros_Home = () => {
    const {sCell, sTab} = useResponsive()
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                backgroundColor: theme.palette.secondary.main,
                overflowX: 'hidden'
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item container lg={6} sm={7} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: sCell ? 4 : 0}}>
                                    <Typography sx={{color: '#fff', fontSize: 32, fontWeight: 800}}>
                                        Nosotros
                                    </Typography>
                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', pr: sCell ? 0 : 8, marginTop: 1}}>
                                    <Typography sx={{
                                        color: '#fff',
                                        fontSize: 18,
                                        fontWeight: 300,
                                        textAlign: sCell ? "center" : 'left'
                                    }}>
                                        Provenimos de familias emprendedoras con más de 30 años de experiencia en el
                                        sector de distribución de granos secos. Este legado nos otorga un profundo
                                        conocimiento del mercado y de las necesidades de nuestros clientes.
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: 4}}>
                                    <Button
                                        onClick={() => navigate('/Nosotros')}
                                        color={"info"} sx={{color: theme.palette.secondary.main}}>Ver mas</Button>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={6} sm={5} xs={12}
                              sx={{justifyContent: 'flex-start', ml: sCell ? -4 : 0, marginTop: sCell ? 6 : 0}}>
                            <img src={granos} width={sCell ? '200%' : sTab ? '300%' : '180%'}/>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Nosotros_Home