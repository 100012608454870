/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import BarraPc from "./BarraPc";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {obtenerSeccionUrl} from "../../Utilidades/obtenerSeccionUrl";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import BarraCell from "./BarraCell";

const Barra = () => {
    const location = useLocation()
    const {masSM} = useResponsive()
    const [barraVerde, setVarraVerde] = useState(false)

    useEffect(() => {
        let seccion = obtenerSeccionUrl({path: location.pathname})
        console.log(seccion)

        if (seccion === 'Nosotros' || seccion === 'Directorio' || seccion === 'Detalle' || seccion === 'Estado' || seccion === 'Equipo') {
            setVarraVerde(true)
        } else {
            setVarraVerde(false)
        }
    }, [location]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{width: '100%'}}
        >

            {masSM ?
                <BarraPc barraVerde={barraVerde}/>
                :
                <BarraCell/>
            }


        </Grid>
    )

}
export default Barra



