/**************************************************
 * Nombre:       Sectores_Mapa
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import Mapa from "./SubComponentes/Mapa";
import {useContext, useEffect, useState} from "react";
import frijol from '../../../Recursos/frijol_poco.png'
import arroz from '../../../Recursos/arroz_poco.png'
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {contextPag} from "../../../App";
import {WhatsApp} from "@mui/icons-material";
import {useDialogoWhatsApp} from "../../Hooks/useDialogoWhatsApp";

const Sectores_Mapa = () => {
    const {sCell, masSM, sTab} = useResponsive()
    const cData = useContext(contextPag)
    const {Dialogo, abrir} = useDialogoWhatsApp()
    const [departamentos, setDepartamentos] = useState([])
    const [escogido, setEscogido] = useState('Nariño')


    const cambiarEscogido = (nombre) => {
        let index = departamentos.findIndex((e) => e.nombre === nombre)
        if (index < 0) {
            setEscogido(nombre)
        } else {
            setEscogido(departamentos[index])
        }


    }

    useEffect(() => {
        setDepartamentos(cData.departamentos)
    }, [cData]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{overflowX: 'hidden'}}
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent={sTab ? 'center' : "flex-start"}
                alignItems="center"
                sx={{maxWidth: '1400px', px: 3}}
            >

                {masSM &&
                    <Grid item container lg={1} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', ml: -20, mr: 20, marginTop: 52}}>
                        <img src={frijol} width={'280%'} height={'auto'}/>
                    </Grid>
                }

                <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"

                    >

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                            <Typography sx={{
                                fontSize: sCell ? 32 : 40,
                                fontWeight: 600,
                                color: theme.palette.secondary.main,
                                textAlign: sCell ? 'center' : 'left',
                                lineHeight: 1.1
                            }}>
                                {departamentos.findIndex((e) => e === escogido) > -1 ? `Presentes en ${escogido}` : `Se el primer distribuidor en ${escogido}`}
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: sCell ? 1 : 0}}>
                            <Typography sx={{
                                fontSize: 18,
                                fontWeight: 400,
                                color: '#00413B',
                                mr: sCell ? 0 : -4,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                {departamentos.findIndex((e) => e === escogido) > -1 ? textoAfirmativo : textoNegativo}
                            </Typography>
                        </Grid>

                        {!sCell &&
                            <>
                                {/*<Grid item container lg={5} sm={4} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 3}}>
                                    <Button color={'secondary'} sx={{color: '#fff'}}>Obtener Catálogo</Button>
                                </Grid>*/}

                                <Grid item container lg={6} sm={6} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 3}}>
                                    <Button
                                        onClick={() => abrir()}
                                        variant={'outlined'} color={'secondary'}
                                        startIcon={<WhatsApp/>}
                                    >Hablar con un asesor</Button>
                                </Grid>

                            </>
                        }


                    </Grid>
                </Grid>

                <Grid item container lg={5} sm={8} xs={12}
                      sx={{justifyContent: sTab ? 'center' : 'flex-start', marginTop: !masSM ? 4 : 0}}>
                    <Mapa escogido={escogido} cambiarEscogido={cambiarEscogido} departamentos={departamentos}/>
                </Grid>

                {sCell &&
                    <>
                        {/*  <Grid item container lg={5} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: 6}}>
                            <Button
                                fullWidth={sCell}
                                color={'secondary'} sx={{color: '#fff'}}>Obtener Catálogo</Button>
                        </Grid>*/}

                        <Grid item container lg={6} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: 2}}>
                            <Button
                                onClick={() => abrir()}
                                startIcon={<WhatsApp/>}
                                fullWidth={sCell}
                                variant={'outlined'} color={'secondary'}>Hablar con un asesor</Button>
                        </Grid>

                    </>
                }

                {masSM &&
                    <Grid item container lg={1} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -40}}>
                        <img src={arroz} width={'280%'} height={'auto'}/>
                    </Grid>
                }


            </Grid>

        </Grid>
    )

}
export default Sectores_Mapa


const textoAfirmativo = 'Ya contamos con distribuidores en este departamento! Únete a nuestra red y forma parte de un equipo que trabaja con productos de alta calidad y una marca en constante crecimiento.'

const textoNegativo = '¡Sé el primero en tu departamento! Disfruta exclusividad, respaldo directo y productos de calidad para impulsar tu negocio con una marca en crecimiento.'