import {irUrl} from "./irUrl";
import {NUMEROWASS} from "../Constante";

export const iniciarChatWhatsApp = (entidad) => {
    if (entidad && entidad.nombre) {
        let nombre = entidad.nombre
        let link = `https://api.whatsapp.com/send?phone=57${NUMEROWASS}&text=Hola%20soy%20${decodeURI(nombre)}%20y%20deseo%20mas%20informaci%C3%B3n`
        irUrl(link)
    } else {
        irUrl(`https://api.whatsapp.com/send?phone=57${NUMEROWASS}&text=Hola%20deseo%20mas%20informaci%C3%B3n`)
    }
}

export const iniciarChatWhatsAppCliente = () => {

    irUrl(`https://api.whatsapp.com/send?phone=57${NUMEROWASS}&text=Hola%2C%20quieros%20saber%20el%20estado%20de%20mi%20pedido`)
}