/**************************************************
 * Nombre:       Seccion_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Frase_Humanizadora from "./Componentes/Frase_Humanizadora";
import Portada_Directorio from "./Componentes/Portada_Directorio";
import Lista_Directorio from "./Componentes/SubComponentes/Lista_Directorio";
import Contactanos from "./Componentes/Contactanos";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useEffect} from "react";
import {marcarEvento} from "../../Servicios/Analiticas/marcarEvento";
import {PRODUCTOS} from "../../Constante";

const Seccion_Directorio = () => {
    const {sCell, masSM} = useResponsive()

    useEffect(() => {
        marcarEvento('directorio')
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: !masSM ? 8 : 14, marginBottom: 0}}>
                <Portada_Directorio/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 6, marginBottom: 0}}>
                <Lista_Directorio/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 16, marginBottom: 12}}>
                <Contactanos/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? -6 : 0, marginBottom: 12}}>
                <Frase_Humanizadora/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Directorio