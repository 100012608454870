/**************************************************
 * Nombre:       BarraCell
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, ButtonBase, Drawer, Grid2, IconButton, Typography} from "@mui/material";
import {theme} from "../../Tema";
import logo from '../../Recursos/logo_blanco.svg'
import {Close, Menu} from "@mui/icons-material";
import {useState} from "react";
import {REDES, SECCIONES} from "../../Constante";
import {irUrl} from "../../Utilidades/irUrl";
import {useNavigate} from "react-router-dom";


const BarraCell = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    return (
        <Grid2 container size={{xs: 12, ms: 12, md: 12}}
               sx={{backgroundColor: '#004438', px: 3, py: 2, width: '100%'}}
        >

            <Grid2 container size={{xs: 6, ms: 6, md: 6}}>
                <img src={logo} width={110} height={'auto'}
                onClick={() => navigate('/Home')}
                />
            </Grid2>

            <Grid2 container size={{xs: 6, ms: 6, md: 6}} sx={{justifyContent: 'flex-end'}}>
                <IconButton
                    onClick={() => setOpen(true)}
                >
                    <Menu sx={{fill: '#fff'}}/>
                </IconButton>
            </Grid2>


            <Drawer open={open} onClose={() => setOpen(false)}
                    anchor={'right'}
            >

                <Grid2 container size={{xs: 12, ms: 12, md: 12}}>
                    <IconButton
                        onClick={() => setOpen(false)}
                    >
                        <Close sx={{fill: '#fff'}}/>
                    </IconButton>
                </Grid2>

                <Grid2 container size={{xs: 12, ms: 12, md: 12}}
                       sx={{width: 300, p: 2, marginTop: 2, justifyContent: 'center'}}>

                    <Grid2 container size={{xs: 12, ms: 12, md: 12}}>
                        {SECCIONES.map((item, index) => {
                            return (
                                <Grid2 container size={{xs: 12, sm: 12, md: 12}}
                                       sx={{justifyContent: 'center', marginTop: 2}}>
                                    <ButtonBase
                                        onClick={() => {
                                            navigate('/' + item)
                                            setTimeout(() => {
                                                setOpen(false)
                                            }, 500)
                                        }}

                                    >
                                        <Typography
                                            sx={{
                                                color: '#fff',
                                                fontSize: 20,
                                                fontWeight: 600,
                                                textAlign: 'center'
                                            }}>{item}</Typography>
                                    </ButtonBase>
                                </Grid2>
                            )
                        })}


                        <Grid2 container size={{xs: 12, sm: 12, md: 12}} sx={{justifyContent: 'center', marginTop: 4}}>
                            <Button
                                onClick={() => {
                                    navigate('/Estado')
                                    setTimeout(() => {
                                        setOpen(false)
                                    }, 500)
                                }}
                                color={'info'} sx={{color: '#004438'}}>
                                Estado de tu pedido
                            </Button>
                        </Grid2>

                    </Grid2>


                    <Grid2 container size={{xs: 12, ms: 12, md: 12}}
                           sx={{bottom: '2%', justifyContent: 'center', marginTop: 60}}>
                        <img src={logo} width={150} height={'auto'}/>
                    </Grid2>

                    <Grid2 container size={{xs: 10, ms: 12, md: 12}}
                           sx={{bottom: '2%', justifyContent: 'center', marginTop: 2}}>
                        {REDES.map((item, index) => {
                            return (
                                <Grid2 container size={{xs: 3, ms: 12, md: 12}} sx={{justifyContent: 'center'}}>
                                    <IconButton
                                        onClick={() => irUrl(item.url)}
                                    >
                                        <img src={item.icono} width={32} height={'auto'}/>
                                    </IconButton>
                                </Grid2>
                            )
                        })}
                    </Grid2>


                </Grid2>

            </Drawer>

        </Grid2>
    )

}
export default BarraCell