/**************************************************
 * Nombre:       Tarjeta_Andres_
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import nataly from '../../../../Recursos/nataly_buraz.png'
import verde from '../../../../Recursos/hoja_verde.svg'
import amarilla from '../../../../Recursos/hoja_amarilla.svg'
import {theme} from "../../../../Tema";
import './Css/mapa.css'
import {useResponsive} from "../../../../Modulo_responsive/Hooks/useResponsive";
import {useNavigate} from "react-router-dom";


const Tarjeta_Nataly = ({acolor = false}) => {
    const {masSM} = useResponsive()
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"

        >


            <Grid item container lg={12} sm={12} xs={12}
                  onClick={() => {
                      if (masSM) {
                          navigate('/Equipo/' + 2)
                      } else {
                          setTimeout(() => {
                              navigate('/Equipo/' + 2)
                          }, 1000)
                      }

                  }}

                  sx={{
                      justifyContent: 'center', zIndex: 1,
                      '&:hover': {
                          filter: 'grayscale(0%)'
                      }
                  }}>
                <img className={acolor ? '' : 'cambio'} src={nataly} width={300} height={'auto'} style={{zIndex: 2}}/>
                <img src={verde} width={110} height={'auto'}
                     style={{marginLeft: -290, marginTop: -10, zIndex: 1, filter: 'grayscale(0%)'}}/>
                <img src={amarilla} width={150} height={'auto'}
                     style={{
                         marginRight: -100,
                         marginTop: 220,
                         zIndex: 3,
                         filter: 'grayscale(0%)',
                         transform: 'rotate(-20deg)'
                     }}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: -5, zIndex: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{p: 2, width: 300, backgroundColor: '#fff', mr: -16, borderRadius: 3, borderTopLeftRadius: 0}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{color: theme.palette.secondary.main, fontSize: 14}}></Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography
                            sx={{color: theme.palette.primary.main, fontSize: 18, fontWeight: 600}}>Nathaly Triana</Typography>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Nataly