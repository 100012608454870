import './App.css';
import {Grid} from "@mui/material";
import Pagina from "./Pagina/Pagina";
import '@fontsource/sora/300.css';
import '@fontsource/sora/400.css';
import '@fontsource/sora/500.css';
import '@fontsource/sora/600.css';
import '@fontsource/sora/700.css';
import '@fontsource/sora/800.css';
import {createContext, useEffect, useState} from "react";
import {obtenerDoc} from "./Servicios/BD/obtenerDoc";
import {iniciarAnaliticas} from "./Servicios/Analiticas/iniciarAnaliticas";


export const contextPag = createContext()

function App() {
    const [departamentos, setDepartamentos] = useState([])

    const valoresProvider = {
        departamentos,
    }

    const {Provider} = contextPag;


    useEffect(() => {
        obtenerDoc('departamentos', 'departamentos').then((des) => {
            if (des.res) {
                let deps = des.data.departamentos
                setDepartamentos(deps)
            }
        })

        iniciarAnaliticas()
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <h1 style={{display: 'none'}}>Distribuidor de granos secos en Colombia</h1>
            <h2 style={{display: 'none'}}>Distribuidor de arroz en Colombia</h2>
            <h2 style={{display: 'none'}}>Distribuidor de frijo en Colombia</h2>
            <h2 style={{display: 'none'}}>Distribuidor de lenteja en Colombia</h2>

            <h2 style={{display: 'none'}}>Distribuidor de tiendas en bogota</h2>
            <h2 style={{display: 'none'}}>Distribuidor de tiendas en cali</h2>
            <h2 style={{display: 'none'}}>Distribuidor de tiendas en popayan</h2>
            <h2 style={{display: 'none'}}>Distribuidor de tiendas en pasto</h2>
            <h2 style={{display: 'none'}}>Distribuidor de tiendas en ipiales</h2>

            <Provider value={valoresProvider}>
                <Pagina/>
            </Provider>

        </Grid>
    );
}

export default App;
