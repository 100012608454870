/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Email, PhoneAndroid, QrCode2} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {theme} from "../../Tema";
import {obtenerDepartamentos} from "../../Divipiola";

const Formulario_Chat_WhatsApp = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto
                        Icono={AccountCircle} nombre={'Tu Nombre'} dato={'nombre'}   colorIcon={theme.palette.primary.main}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelect
                        Icono={QrCode2} nombre={'Tu Departamento'} dato={'departamento'}   {...props}
                        opciones={obtenerDepartamentos()}
                    />
                </Grid>




            </Grid>
        </form>

    )

}
export default Formulario_Chat_WhatsApp