/**************************************************
 * Nombre:       Contactanos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import {useFormulario} from "../../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Pedido from "../../Formularios/Formulario_Pedido";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {marcarEvento} from "../../../Servicios/Analiticas/marcarEvento";

const Estado_Formulario = ({funcion}) => {
    const {sCell, masSM} = useResponsive()
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: {}})


    const obtenerDato = async () => {


        marcarEvento('consulto_pedido')

        let entidad = await obtenerEntidad()
        if (entidad.numero) {
            funcion(entidad.numero)
        }
    }


    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{backgroundColor: theme.palette.primary.main, borderRadius: !masSM ? 6 : 10}}
        >

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', p: !masSM ? 3 : 6, pr: !masSM ? 3 : 12}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', pl: 1}}>
                        <Typography sx={{fontSize: !masSM ? 18 : 32, fontWeight: 600, color: '#fff'}}>Datos del
                            Pedido</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                        <Formulario_Pedido props={props}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: {xs: 'center', sm: 'flex-start'}, pl: 1, marginTop: 3}}>
                        <Button
                            onClick={() => obtenerDato()}
                            color={'info'} sx={{color: theme.palette.primary.main}}>Consultar</Button>
                    </Grid>

                </Grid>

            </Grid>


        </Grid>
    )

}
export default Estado_Formulario