/**************************************************
 * Nombre:       Portada_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import fondo from '../../../Recursos/fondo_video_portada.mp4'
import fondo_img from '../../../Recursos/fondo_img_portada.png'
import paquetes from '../../../Recursos/paquetes_producto.png'
import {WhatsApp} from "@mui/icons-material";
import {theme} from "../../../Tema";
import {useNavigate} from "react-router-dom";
import {PRODUCTOS} from "../../../Constante";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {useDialogoWhatsApp} from "../../Hooks/useDialogoWhatsApp";

const Portada_Home = () => {
    const {sCell, sTab} = useResponsive()
    const navigate = useNavigate()
    const {Dialogo, abrir} = useDialogoWhatsApp()


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >

            <Dialogo/>

            <video width={'100%'} height={630}
                   style={{
                       objectFit: 'cover', borderBottomRightRadius: 230,
                       zIndex: 1
                   }}
                   id="background-video" autoPlay  loop muted poster={fondo_img}>
                <source src={fondo} type="video/mp4"/>
            </video>

            <Grid item container lg={12} sm={12} xs={12} sx={{
                justifyContent: 'center',
                backgroundImage: 'linear-gradient(90.24deg, rgba(0, 0, 0, 0.5) 0.77%, rgba(0, 0, 0, 0) 99.76%)',
                zIndex: 2,
                height: 650,
                marginTop: -81.5,
                borderBottomRightRadius: 240,
                alignItems: 'flex-start',
            }}>


                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', px: 3, overflowX: 'hidden'}}
                >

                    <Grid item container lg={6} sm={8} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 28}}>
                                <Typography
                                    sx={{color: '#fff', fontSize: sCell ? 32 : 48, fontWeight: 300, lineHeight: 1.1}}>
                                    Llevamos a tu mesa y negocio los mejores granos de <span style={{fontWeight: 700}}>nuestra tierra</span>
                                </Typography>
                            </Grid>


                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                <Typography
                                    sx={{color: '#fff', fontSize: 16, fontWeight: 400, lineHeight: 1.1, pl: 0.5}}>
                                    La calidad y tiempo de entrega no es un estándar es una promesa
                                </Typography>
                            </Grid>


                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 6}}>
                                <Button
                                    onClick={() => abrir()}
                                    endIcon={<WhatsApp/>} color={'secondary'} variant={'contained'}
                                    sx={{color: '#fff'}}>Más información</Button>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item container lg={6} sm={4} xs={12} sx={{justifyContent: 'flex-start'}}/>

                    {!sCell &&
                        <Grid item container lg={4} sm={5} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sTab ? 10 : 20, zIndex: 1}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={sTab ? 2 : 3}
                                sx={{marginLeft: sTab ? -5 : -15}}
                            >

                                {PRODUCTOS.map((it, index) => {
                                    return (
                                        <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'flex-start'}}>
                                            <Button
                                                onClick={() => navigate('/Detalle/' + index)}
                                                fullWidth variant={'outlined'}
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: '#fff'
                                                    }
                                                }}
                                            >{it.id}</Button>
                                        </Grid>
                                    )
                                })}


                            </Grid>
                        </Grid>
                    }

                    <Grid item container lg={8} sm={8} xs={12}
                          sx={{
                              justifyContent: sCell ? 'center' : 'flex-end',
                              marginTop: sCell ? 2 : -14,
                              marginRight: sCell ? 0 : -12,
                              overflowX: 'hidden',
                              zIndex: 2
                          }}>
                        <img src={paquetes} width={sCell ? '140%' : '120%'}/>
                    </Grid>

                    {sCell &&
                        <Grid item container lg={4} sm={4} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 0 : 20, zIndex: 1}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={3}
                                sx={{marginLeft: sCell ? -3 : -15}}
                            >

                                {PRODUCTOS.map((it, index) => {
                                    return (
                                        <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: 'flex-start'}}>
                                            <Button
                                                onClick={() => navigate('/Detalle/' + index)}
                                                fullWidth
                                                variant={'outlined'}
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: '#fff'
                                                    }
                                                }}
                                            >{it.id}</Button>
                                        </Grid>
                                    )
                                })}


                            </Grid>
                        </Grid>
                    }

                </Grid>

            </Grid>


        </Grid>
    )

}
export default Portada_Home

const productos = ['Arroz', 'Lenteja', 'Frijol']