/**************************************************
 * Nombre:       Seccion_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Frase_Humanizadora from "./Componentes/Frase_Humanizadora";
import Equipo_Trabajo from "./Componentes/Equipo_Trabajo";
import Portada_Detalle_Equipo from "./Componentes/Portada_Detalle_Equipo";
import Contactanos from "./Componentes/Contactanos";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {EQUIPOTRABAJO} from "../../Constante";
import {irArriba} from "../../Utilidades/irArriba";

const Seccion_Detalle_Equipo = () => {
    const {sCell, sTab} = useResponsive()
    const parms = useParams()
    const [persona, setPersona] = useState({})

    useEffect(() => {

        irArriba()

        if (parms && parms.id) {
            let index = parseInt(parms.id)
            setPersona(EQUIPOTRABAJO[index])
        }
    }, [parms]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? 8 : 14, marginBottom: 0}}>
                <Portada_Detalle_Equipo persona={persona}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? 0 : sTab ? 4 : -12, marginBottom: 0}}>
                <Frase_Humanizadora/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 0, marginBottom: 0}}>
                <Contactanos/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 12, marginBottom: 12}}>
                <Equipo_Trabajo/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Detalle_Equipo