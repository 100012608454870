/**************************************************
 * Nombre:       Portada_Detalle_Equipo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Portada_Directorio = () => {
    const {sCell, masSM} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >
                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={4}
                    >


                        <Grid item container lg={5} sm={5} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography
                                sx={{color: '#00413B', fontSize: sCell ? 32 : 40, fontWeight: 700, lineHeight: 1.1, textAlign: sCell ? 'center' : 'left'}}>

                                Presencia Nacional y Crecimiento
                            </Typography>
                        </Grid>

                        <Grid item container lg={7} sm={7} xs={12}
                              sx={{justifyContent: 'flex-end', marginTop: 0}}>
                            <Typography
                                sx={{color: '#00413B', fontSize: 18, fontWeight: 400, textAlign: sCell ? 'center' : 'right'}}>
                                Estamos presentes en varias ciudades de Colombia con puntos de venta estratégicos.
                                Nuestro objetivo es seguir creciendo, llevando nuestros productos a más lugares del
                                país. Consulta nuestro directorio para encontrarnos cerca de ti.
                            </Typography>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Portada_Directorio