/**************************************************
 * Nombre:       Contactanos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import mujer from '../../../Recursos/mujer_contestando.png'
import {useFormulario} from "../../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Mediciones_Agua from "../../Formularios/Formulario_Mediciones_Agua";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";
import {useLoaders} from "../../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../../Recursos/logo_redondo.svg'
import {useAvisos} from "../../../Modulo_Avisos/Hooks/useAvisos";


const Contactanos = () => {
    const {sCell, masSM, sTab} = useResponsive()
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: {}})
    const {abrirCargador, cerrarCargador, setDetener, Cargador} = useLoaders({logo: logo})
    const {nuevoAviso, terminar, Avisos} = useAvisos({funcionUno: cerrarCargador, funcionDos: limpiarEntidad})

    const subirDatos = async () => {

        let entidad = await obtenerEntidad()
        abrirCargador('Subiendo Datos')
        entidad.fecha = new Date().getTime()
        entidad.estado = 'Nuevo'
        entidad.origen = 'Formulario'
        let result = await guardarDoc('potenciales', entidad)
        nuevoAviso({aviso: 'Datos subidos con exito'})
        if (result.res) {
            setTimeout(() => {
                setDetener(true)
                terminar({aviso: 'Pronto nos comunicaremos contigo'})
            }, 1000)
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Cargador/>

                <Avisos/>

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{backgroundColor: theme.palette.primary.main, borderRadius: 10}}
                    >

                        <Grid item container lg={7} sm={7} xs={12} sx={{
                            justifyContent: 'flex-start',
                            p: !masSM ? 3 : 6,
                            pr: sCell ? 3 : 12,
                            marginTop: sCell ? 2 : 0
                        }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', pl: 1}}>
                                    <Typography sx={{
                                        fontSize: !masSM ? 24 : 32,
                                        fontWeight: 600,
                                        color: '#fff',
                                        lineHeight: 1.1
                                    }}>Déjanos contactarte</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Formulario_Mediciones_Agua props={props}/>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', pl: 1, marginTop: 3, pr: sCell ? 1 : 0}}>
                                    <Button
                                        onClick={() => subirDatos()}
                                        fullWidth={sCell}
                                        color={'info'} sx={{color: theme.palette.primary.main}}>Enviar</Button>
                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item container lg={5} sm={5} xs={12} sx={{
                            justifyContent: 'flex-start',
                            background: `url(${mujer})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: sCell ? 'top' : 'center',
                            borderTopRightRadius: sCell ? 0 : 40,
                            borderBottomRightRadius: 40,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: sCell ? 40 : 0,
                            height: sCell ? '60vw' : sTab ? '45vw' : '34vw',
                            maxHeight: 450,
                            marginTop: sCell ? 2 : 0

                        }}>

                        </Grid>


                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Contactanos