/**************************************************
 * Nombre:       Estado_Pedido
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import Estado_Formulario from "./Estado_Formulario";
import {theme} from "../../../Tema";
import {WhatsApp} from "@mui/icons-material";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {iniciarChatWhatsAppCliente} from "../../../Utilidades/iniciarChatWhatsApp";
import {useState} from "react";
import {useLoaders} from "../../../Modulo_Loaders/Hooks/useLoaders";
import logo from "../../../Recursos/logo_redondo.svg";
import {obtenerColeccionQuery} from "../../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../../fire";
import {formatoFecha} from "../../../Utilidades/formatoFecha";

const Estado_Pedido = () => {
    const {sCell, masSM} = useResponsive()
    const [pedido, setPedido] = useState({})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})


    const buscarIdentificador = async (numero) => {
        abrirCargador('Consultando Pedido')

        let q = query(collection(fire, 'despachos'), where('identificador', '==', numero))
        let result = await obtenerColeccionQuery(q)
        if (result.res && result.data[0]) {
            setPedido(result.data[0])
        } else {
            setPedido({identificador: ' No encontrado'})
        }
        cerrarCargador()
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Cargador/>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >


                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: 'flex-start', pr: sCell ? 0 : 4}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                    <Typography sx={{
                                        color: '#004438',
                                        fontSize: 32,
                                        fontWeight: 800,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>Rastreo de
                                        Pedidos</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{
                                        color: '#004438',
                                        fontSize: !masSM ? 16 : 20,
                                        fontWeight: 400,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                        Ofrecemos una herramienta de seguimiento que permite a nuestros distribuidores
                                        conocer la ubicación de sus pedidos y su fecha de entrega. Así, garantizamos
                                        mayor transparencia y planificación en su operación.
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 0}}>
                            <Estado_Formulario funcion={buscarIdentificador}/>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{
                            justifyContent: 'flex-start',
                            borderTop: 1,
                            borderColor: theme.palette.primary.main,
                            marginTop: 8
                        }}/>

                        <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                            <Typography sx={{color: '#004438', fontSize: 32, fontWeight: 800}}>
                                {pedido.identificador ? 'Pedido #' + pedido.identificador : ''}
                            </Typography>
                        </Grid>

                        <Grid item container lg={4} sm={4} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 4}}>
                            <Typography sx={{color: '#004438', fontSize: 18, fontWeight: 400}}>
                                {pedido.fechaSalida ? `Fechad de Despacho: ${formatoFecha(pedido.fechaSalida)}` : ''}
                                <br/>
                                {pedido.fechaLlegada ? `Fechad de Llegada: ${formatoFecha(pedido.fechaLlegada)}` : ''}
                            </Typography>
                        </Grid>


                        <Grid item container lg={4} sm={4} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: 4}}>
                            {pedido.identificador &&
                                <Button
                                    onClick={() => iniciarChatWhatsAppCliente()}
                                    color={'secondary'} sx={{color: '#fff'}} endIcon={<WhatsApp/>}>Hablar con
                                    asesor</Button>
                            }
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Estado_Pedido