/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Seccion_Home from "./Secciones/Seccion_Home";
import Barra from "./Barra/Barra";
import Footer from "./Footer";
import Seccion_Detalle_Producto from "./Secciones/Seccion_Detalle_Producto";
import Seccion_Nosotros from "./Secciones/Seccion_Nosotros";
import Seccion_Detalle_Equipo from "./Secciones/Seccion_Detalle_Equipo";
import Seccion_Directorio from "./Secciones/Seccion_Directorio";

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Seccion_Estado from "./Secciones/Seccion_Estado";

const Pagina = () => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{backgroundColor: '#F7F2EC', maxWidth: '100wh'}}
        >

            <Router>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', zIndex: 3}}>
                    <Barra/>
                </Grid>

                <Routes style={{zIndex: 1}}>

                    <Route path={"*"} element={<Seccion_Home/>}/>
                    <Route path={"/Home"} element={<Seccion_Home/>}/>
                    <Route path={"/Home/:id"} element={<Seccion_Home/>}/>
                    <Route path={"/Nosotros"} element={<Seccion_Nosotros/>}/>
                    <Route path={"/Directorio"} element={<Seccion_Directorio/>}/>
                    <Route path={"/Detalle/:id"} element={<Seccion_Detalle_Producto/>}/>
                    <Route path={"/Equipo/:id"} element={<Seccion_Detalle_Equipo/>}/>
                    <Route path={"/Estado"} element={<Seccion_Estado/>}/>


                </Routes>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Footer/>
                </Grid>

            </Router>

        </Grid>
    )

}
export default Pagina    