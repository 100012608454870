/**************************************************
 * Nombre:       Dialogo_Chat_WhatsApp
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Chat_WhatsApp from "../Formularios/Formulario_Chat_WhatsApp";
import {theme} from "../../Tema";
import {WhatsApp} from "@mui/icons-material";
import {iniciarChatWhatsApp} from "../../Utilidades/iniciarChatWhatsApp";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {marcarEvento} from "../../Servicios/Analiticas/marcarEvento";


const Dialogo_Chat_WhatsApp = () => {
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: {}})
    const [cargando, setCargando] = useState(false)

    const guardarDatos = async () => {

        let entidad = await obtenerEntidad()
        setCargando(true)
        if (entidad.nombre) {
            entidad.fecha = new Date().getTime()
            entidad.estado = 'Nuevo'
            entidad.origen = 'Chat WhatsApp'
            let resultados = await guardarDoc('potenciales', entidad)
            setCargando(false)
            iniciarChatWhatsApp(entidad)

        } else {
            setCargando(false)
            iniciarChatWhatsApp()

        }


    }


    useEffect(() => {
        marcarEvento('click_boton_wass')
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', px: 2}}>
                <Typography sx={{fontSize: 24, fontWeight: 600, lineHeight: 1.1, textAlign: 'center'}}>Permitenos
                    brindarte una atencion <span style={{color: theme.palette.primary.main}}>personalizada</span>
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Formulario_Chat_WhatsApp props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2, marginBottom: 2}}>
                <LoadingButton
                    variant={'contained'}
                    loading={cargando}
                    onClick={() => guardarDatos()}
                    startIcon={<WhatsApp/>}>Iniciar Chat</LoadingButton>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Chat_WhatsApp    