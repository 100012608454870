/**************************************************
 * Nombre:       Tienda_Frutos_Secos_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import chica from '../../../Recursos/chica_apuntando.png'
import frutos from '../../../Recursos/frutos_secos.png'
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";


const Tienda_Frutos_Secos_Home = () => {
    const {sCell,sTab} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{overflowX: 'hidden'}}

        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginBottom: -30}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontSize: 32,
                                fontWeight: 800,
                                textAlign: 'center'
                            }}>Tienda
                                Saludable</Typography>
                        </Grid>

                        <Grid item container lg={4} sm={6} xs={12}
                              sx={{justifyContent: 'center', marginTop: 1, marginBottom: 0}}>
                            <Typography sx={{color: '#00413B', fontSize: 16, fontWeight: 400, textAlign: 'center'}}>
                                Explora nuestra línea de frutos secos, perfecta para todos los amantes de un estilo de
                                vida
                                saludable. ¡Disponible solo en nuestra tienda online!
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4, marginBottom: sTab ? 14 : 0}}>
                            <Button disabled >Muy pronto</Button>
                        </Grid>

                    </Grid>
                </Grid>

                {!sCell &&
                    <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <img src={chica} width={'100%'}/>
                    </Grid>
                }
                <Grid item container lg={8} sm={8} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 14 : 0}}>
                    <img src={frutos} width={ sTab ? '120%' : '140%'}/>
                </Grid>


            </Grid>
        </Grid>
    )

}
export default Tienda_Frutos_Secos_Home