import {useDialogo} from "../../Modulo_Dialogos_2025/Hooks/useDialogo";
import Dialogo_Chat_WhatsApp from "../Dialogos/Dialogo_Chat_WhatsApp";

export const useDialogoWhatsApp = () => {
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Chat_WhatsApp})


    return {
        Dialogo,
        abrir
    }

}