/**************************************************
 * Nombre:       Seccion_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Llevalo_Casa from "./Componentes/Llevalo_Casa";
import Llevalo_Negocio from "./Componentes/Llevalo_Negocio";
import Frase_Humanizadora from "./Componentes/Frase_Humanizadora";
import Detalle_Producto_Detalle from "./Componentes/Detalle_Producto_Detalle";
import Portada_Nosotros from "./Componentes/Portada_Nosotros";
import Historia_Nosotros from "./Componentes/Historia_Nosotros";
import Equipo_Trabajo from "./Componentes/Equipo_Trabajo";
import Estado_Pedido from "./Componentes/Estado_Pedido";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useEffect} from "react";
import {marcarEvento} from "../../Servicios/Analiticas/marcarEvento";

const Seccion_Estado = () => {
    const {sCell} = useResponsive()

    useEffect(() => {
        marcarEvento('estado_pedido')
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? -4 : 0}}>
                <Estado_Pedido/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <Frase_Humanizadora/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Estado