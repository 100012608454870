/**************************************************
 * Nombre:       Botones_Ciudades
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import {useRef, useState} from "react";
import {useResponsive} from "../../../../Modulo_responsive/Hooks/useResponsive";
import {useNavigate} from "react-router-dom";


const Botones_Ciudades = ({items}) => {
    const {sCell} = useResponsive()
    const ref = useRef()
    const navigate = useNavigate()


    return (
        <Grid
            container
            direction="row"
            justifyContent={sCell ? 'center' : "flex-start"}
            alignItems="flex-start"
        >


            {items.map((it) => {
                return (
                    <Button
                        onClick={() => navigate('/Directorio')}
                        variant={'outlined'} sx={{m: 0.5}}>{it}</Button>
                )
            })}


        </Grid>
    )

}
export default Botones_Ciudades