/**************************************************
 * Nombre:       Historia_Nosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import frijol from '../../../Recursos/manos_frijol.png'
import mano from '../../../Recursos/manos_alverja.png'
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";


const Historia_Nosotros = () => {
    const {sCell, masSM} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                            <img src={frijol} width={'90%'} style={{borderRadius: 32}}/>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 0}}>
                                    <Typography
                                        sx={{
                                            color: '#00413B',
                                            fontSize: !masSM ? 32 : 40,
                                            fontWeight: 600,
                                            lineHeight: 1.1,
                                            textAlign: sCell ? 'center' : 'left'
                                        }}>Confianza
                                        y Calidad Garantizada</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{
                                          justifyContent: 'flex-start',
                                          marginTop: 2,
                                          textAlign: sCell ? 'center' : 'left'
                                      }}>
                                    <Typography
                                        sx={{color: '#00413B', fontSize: 18, fontWeight: 400}}>
                                        Queremos transmitir confianza, seguridad, estabilidad y tranquilidad. Cuando el
                                        cliente nos da esa oportunidad de elegir nuestros productos, está depositando
                                        confianza en nosotros. Nuestra retribución como empresa es brindarle
                                        estabilidad, tranquilidad y seguridad de que está adquiriendo un producto con
                                        altos estándares de calidad, lo que permitirá en un futuro, cuando el cliente
                                        regrese, nuestra marca sea su preferida o su marca principal en cualquier tipo
                                        de producto que la empresa ofrezca.
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        {sCell &&

                            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: sCell ? 'center' : 'flex-end', marginTop: 12}}>
                                <img src={mano} width={'90%'} style={{borderRadius: 32}}/>
                            </Grid>
                        }

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: 12, pr: 4}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                    <Typography
                                        sx={{color: '#00413B', fontWeight: 600, lineHeight: 1.1, fontSize: !masSM ? 32 : 40,}}>
                                        Precios Estables
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Typography
                                        sx={{color: '#00413B', fontSize: 18, fontWeight: 400, textAlign: sCell ? 'center' : 'left'}}>
                                        Estamos en un sector donde influyen muchas variables externas como la TRM,
                                        cultivos estacionales, variaciones de clima, etc. Desde los planes de “BURAZ”, y
                                        dentro de nuestras posibilidades propenderemos a estabilizar nuestros precios en
                                        donde las variaciones del mercado afecten en menor proporción los precios de
                                        nuestros productos, y procuraremos que el impacto al consumidor final sea el
                                        mínimo posible.
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>



                        {!sCell &&

                            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-end', marginTop: 12}}>
                                <img src={mano} width={'90%'} style={{borderRadius: 32}}/>
                            </Grid>
                        }

                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default Historia_Nosotros