/**************************************************
 * Nombre:       Tarjeta_Ciudad
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Chip, Grid, Typography} from "@mui/material";
import Tarjeta_Granero from "./Tarjeta_Granero";
import {theme} from "../../../../Tema";
import {useResponsive} from "../../../../Modulo_responsive/Hooks/useResponsive";
import {useEffect, useState} from "react";
import {marcarEvento} from "../../../../Servicios/Analiticas/marcarEvento";


const Tarjeta_Ciudad = ({item}) => {
    const {sCell} = useResponsive()
    const [escogido, setEscogido] = useState({})

    useEffect(() => {
        let puntos = item.puntos
        setEscogido(puntos[0])
    }, [item]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{backgroundColor: '#fff', borderRadius: 4}}
        >

            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{p: 4}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{fontSize: sCell ? 16 : 18, color: '#004438',}}>Puntos de venta</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -1}}>
                        <Typography sx={{
                            fontSize: sCell ? 32 : 40,
                            color: '#004438',
                            fontWeight: 700
                        }}>{item.ciudad}</Typography>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{px: 2, marginTop: 1}}
                    >

                        {item.puntos.map((it, index) => {
                            return (
                                <Chip
                                    onClick={() => {
                                        marcarEvento('click ' + it.nombre)
                                        setEscogido(it)
                                    }}
                                    label={it.razon} variant={"outlined"} color={"primary"}
                                    sx={{
                                        mt: 1, ml: 1,
                                        transition: 'all .2 ease-in-out',
                                        cursor: 'pointer',
                                        backgroundColor: escogido.nombre === it.nombre ? theme.palette.primary.main : '#fff',
                                        color: escogido.nombre === it.nombre ? '#fff' : theme.palette.primary.main,
                                        '&:hover': {
                                            backgroundColor: theme.palette.primary.main,
                                            color: theme.palette.primary.main
                                        }
                                    }}

                                />
                            )

                        })}

                    </Grid>

                </Grid>
            </Grid>

            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Tarjeta_Granero escogido={escogido}/>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Ciudad    