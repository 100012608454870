/**************************************************
 * Nombre:       Equipo_Trabajo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import Tarjeta_Oscar from "./SubComponentes/Tarjeta_Oscar";
import {theme} from "../../../Tema";
import Tarjeta_Andres from "./SubComponentes/Tarjeta_Andres";
import Tarjeta_Nataly from "./SubComponentes/Tarjeta_Nataly";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Equipo_Trabajo = () => {
    const {sCell, sTab, masSM} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Typography sx={{
                        color: theme.palette.primary.main,
                        fontSize: sCell ? 32 : 40,
                        fontWeight: 700,
                        textAlign: 'center'
                    }}>Equipo de
                        trabajo</Typography>
                </Grid>

                <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Typography
                        sx={{
                            color: theme.palette.primary.main,
                            fontSize: sCell ? 16 : 20,
                            fontWeight: 400,
                            textAlign: 'center'
                        }}>
                        Contamos con un equipo experto, comprometido con la calidad y la satisfacción de nuestros
                        clientes.
                    </Typography>
                </Grid>


                <Grid item container lg={11} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: 4, pr: 4, ml: sCell ? -12 : -16}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >


                        <Grid item container lg={4} sm={6} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 18}}>
                            <Tarjeta_Oscar/>
                        </Grid>

                        <Grid item container lg={4} sm={6} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 6 : 0}}>
                            <Tarjeta_Andres/>
                        </Grid>

                        <Grid item container lg={4} sm={12} xs={12}
                              sx={{justifyContent: sTab ? 'flex-end' : 'flex-start', marginTop: !masSM ? 6 : 30}}>
                            <Tarjeta_Nataly/>
                        </Grid>


                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default Equipo_Trabajo