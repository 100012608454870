/**************************************************
 * Nombre:       Precios_Competitivos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import tendero from '../../../Recursos/tendero_feliz.png'
import {theme} from "../../../Tema";
import ic_calidad from '../../../Recursos/ic_calidad.svg'
import ic_precios from '../../../Recursos/ic_precios.svg'
import ic_calendario from '../../../Recursos/ic_caledario.svg'
import ic_calidad_atencio from '../../../Recursos/ic_calidad_atencion.svg'
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Precios_Competitivos = () => {
    const {sCell, sTab, masSM} = useResponsive()

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 52.27%, rgba(0, 0, 0, 0.8) 88.94%), url(${tendero})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'top',
                    backgroundRepeat: 'no-repeat',
                    borderTopLeftRadius: !masSM ? 100 :  260,
                    pb: 4

                }}
            >

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', px: 3}}
                >

                    {!sCell &&
                        <Grid item container lg={6} sm={7} xs={6} sx={{justifyContent: 'flex-end'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    p: sTab ? 4 : 10,
                                    borderBottomLeftRadius: 80,
                                    marginRight:  -3
                                }}
                            >


                                <Typography sx={{textAlign: 'rigth', color: '#fff', fontSize: 24}}>
                                    Garantizamos precios competitivos, tiempos de entrega claros y atención al cliente
                                    excepcional. Elige Buraz para disfrutar de
                                    <span style={{fontWeight: 800, paddingLeft: 5}}>
                                 alimentos saludables, de calidad y excelente
                            precio
                            </span>

                                </Typography>

                            </Grid>
                        </Grid>
                    }

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: sCell ? 40 : sTab ? 20 : 32}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={sCell ? 2 : 0}
                        >

                            {valores.map((item, index) => {
                                return (
                                    <Grid item container lg={3} sm={3} xs={6}
                                          sx={{
                                              justifyContent: 'flex-start',
                                              borderRight: valores.length === index + 1 && !sCell ? 0 : 1,
                                              borderColor: '#fff'
                                          }}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >

                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{justifyContent: 'center'}}>
                                                <img src={item.icono} width={!masSM ? 40 : 50} height={'auto'}/>
                                            </Grid>

                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{justifyContent: 'center', marginTop: 2, textAlign: 'center'}}>
                                                <Typography sx={{
                                                    color: '#fff',
                                                    fontSize: !masSM ? 14 : 16
                                                }}>{item.nombre}</Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>


                </Grid>


            </Grid>

            {sCell &&
                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{backgroundColor: theme.palette.primary.main, p: 4, borderBottomLeftRadius: 40}}
                    >


                        <Typography sx={{textAlign: 'center', color: '#fff', fontSize: 18}}>
                            Garantizamos precios competitivos, tiempos de entrega claros y atención al cliente
                            excepcional. Elige Buraz para disfrutar de
                            <span style={{fontWeight: 800, paddingLeft: 5}}>
                                 alimentos saludables, de calidad y excelente
                            precio
                            </span>

                        </Typography>

                    </Grid>
                </Grid>
            }

        </>
    )

}
export default Precios_Competitivos

const valores = [
    {
        nombre: 'Calidad en Producto',
        icono: ic_calidad
    },
    {
        nombre: 'Precios Competitivos',
        icono: ic_precios
    },
    {
        nombre: 'Tiempos de Entrega',
        icono: ic_calendario
    },
    {
        nombre: 'Calidad en atención',
        icono: ic_calidad_atencio
    },
]