/**************************************************
 * Nombre:       Lista_Directorio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Tarjeta_Ciudad from "./Tarjeta_Ciudad";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../../../Servicios/BD/obtenerCol";
import {obtenerDepartamentos} from "../../../../Divipiola";

const Lista_Directorio = () => {
    const [items, setItems] = useState([])

    const ordenarItems = (clientes) => {

        let arr = []
        let departamentos = obtenerDepartamentos()
        for (let i = 0; i < departamentos.length; i++) {


            let arrClis = []
            for (let j = 0; j < clientes.length; j++) {

                if (clientes[j].departamento === departamentos[i]) {
                    arrClis.push(clientes[j])
                }
            }


            if (arrClis.length > 0) {

                let obj = {
                    ciudad: departamentos[i],
                    puntos: arrClis
                }

                arr.push(obj)
            }
        }

        setItems(arr)

    }

    useEffect(() => {
        obtenerCol('clientes').then((dox) => {
            if (dox.res) {

                ordenarItems(dox.data)
            }
        })
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >
                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        {items.map((item, index) => {
                            return (
                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 6}}>
                                    <Tarjeta_Ciudad item={item}/>
                                </Grid>

                            )
                        })}

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Lista_Directorio


const items = [
    {
        ciudad: 'Cali',
        puntos: [
            {
                nombre: 'Granero Polo',

            },
            {
                nombre: 'Granero Magnioloa',

            },
            {
                nombre: 'Industria Lechera',

            },
        ]
    },
    {
        ciudad: 'Nariño',
        puntos: [
            {
                nombre: 'Granero Polo',

            },
            {
                nombre: 'Granero Magnioloa',

            },

        ]
    }
]
