/**************************************************
 * Nombre:       Sectores_Mapa
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {useState} from "react";
import ramas from '../../../Recursos/ramas.png'
import lenteja from '../../../Recursos/lenteja.png'
import logo from '../../../Recursos/logo_verde.svg'
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Frase_Humanizadora = () => {
    const {sCell, sTab, masSM} = useResponsive()


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{overflowX: 'hidden'}}
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: '1400px', px: 3, py: sTab ? 8 : 0}}
            >
                {!sTab &&
                    <Grid item container lg={1} sm={1} xs={1}
                          sx={{
                              justifyContent: 'flex-start',
                              ml: sCell ? -10 : -30,
                              mr: 30,
                              marginTop: sCell ? 4 : 52,
                              marginBottom: sCell ? -8 : 0
                          }}>
                        <img src={ramas} width={sCell ? '500%' : '320%'} height={'auto'}/>
                    </Grid>
                }

                <Grid item container lg={10} sm={12} xs={10}
                      sx={{justifyContent: 'center', mr: sCell ? -3 : 0, ml: sCell ? 3 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                    >

                        <Grid item container lg={5} sm={5} xs={6}
                              sx={{
                                  justifyContent: 'flex-start',
                                  borderRight: 2,
                                  borderColor: '#00413B',
                                  pr: sCell ? 2 : 8
                              }}>
                            <Typography sx={{
                                color: '#00413B',
                                fontSize: sCell ? 16 : sTab ? 20 : 32,
                                fontWeight: 600,
                                lineHeight: 1.2,
                                textAlign: sCell ? 'center' : 'left'
                            }}>"Con
                                cada grano que elegimos,
                                cultivamos confianza para tu
                                hogar y tu negocio."</Typography>
                        </Grid>


                        <Grid item container lg={5} sm={5} xs={6} sx={{justifyContent: 'flex-end'}}>
                            <img src={logo} width={'80%'} height={'auto'}/>
                        </Grid>

                    </Grid>


                </Grid>

                {!sTab &&
                    <Grid item container lg={1} sm={1} xs={1}
                          sx={{justifyContent: 'flex-start', marginTop: sCell ? 20 : -40, ml: sCell ? 0 : -6}}>
                        <img src={lenteja} width={sCell ? '500%' : '360%'} height={'auto'}/>
                    </Grid>
                }

            </Grid>

        </Grid>
    )

}
export default Frase_Humanizadora

const departamentos = [
    {
        nombre: 'nariño',
        descripcion: 'Nariño como nuestro departamento de origen es la cuna de la comercialización de nuestros productos, nuestros clientes dan fe de nuestra calidad-precio.'

    },
    {
        nombre: 'cauca',
        descripcion: 'Estamos presentes gracias a la comunidad'

    }
]