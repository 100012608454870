/**************************************************
 * Nombre:       Portada_Nosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import fondo from '../../../Recursos/fondo_nosotros.png'
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Portada_Nosotros = () => {
    const {sCell, sTab, masSM} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{
                      justifyContent: 'center',
                      background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 39.22%, rgba(0, 0, 0, 0.8) 80.44%), url(${fondo})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      borderBottomRightRadius: !masSM ? 80 : 240,
                  }}
            >


                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', px: 3,}}
                >

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: !masSM ? 16 : 42, marginBottom: sCell ? 4 : 0}}>
                        <Typography
                            sx={{color: '#fff', fontSize: sCell ? 32 : 36, fontWeight: 700}}>Nosotros</Typography>
                    </Grid>

                    {!sCell &&
                        <Grid item container lg={6} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginBottom: !masSM ? 4 : 12}}>
                            <Typography sx={{color: '#fff', fontSize: sCell ? 14 : 18, fontWeight: 300}}>Venimos de
                                familias
                                emprendedoras con más de 30 años de experiencia en este sector. Encontramos una gran
                                oportunidad para ofrecer productos de calidad a precios competitivos, teniendo en cuenta
                                que
                                contamos con conocimiento del mercado y la existencia de una base de datos de clientes
                                fijos. Y por supuesto con el gran objetivo de posicionar la marca en el sur del país y
                                poderla llevar al interior del país</Typography>
                        </Grid>
                    }

                </Grid>

            </Grid>

            {sCell &&
                <Grid item container lg={6} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', px: 3, marginTop: 3}}>
                    <Typography sx={{color: '#00413B', fontSize: 18, fontWeight: 300}}>Venimos de
                        familias
                        emprendedoras con más de 30 años de experiencia en este sector. Encontramos una gran
                        oportunidad para ofrecer productos de calidad a precios competitivos, teniendo en cuenta
                        que
                        contamos con conocimiento del mercado y la existencia de una base de datos de clientes
                        fijos. Y por supuesto con el gran objetivo de posicionar la marca en el sur del país y
                        poderla llevar al interior del país</Typography>
                </Grid>
            }

        </Grid>
    )

}
export default Portada_Nosotros