/**************************************************
 * Nombre:       Tarjeta_Granero
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import buraz from '../../../../Recursos/buraz_verde.png'
import {ArrowForward} from "@mui/icons-material";
import {useResponsive} from "../../../../Modulo_responsive/Hooks/useResponsive";
import {irMapa} from "../../../../Utilidades/irMapa";

const Tarjeta_Granero = ({escogido}) => {
    const {sCell, masSM} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems={sCell ? 'flex-start' : "center"}
            sx={{
                background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 40.42%, #004438 100%), url(${escogido.img ? escogido.img : buraz})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                p: 3,
                borderBottomRightRadius: 20,
                borderTopRightRadius: sCell ? 0 : 20,
                borderBottomLeftRadius: sCell ? 20 : 0,
                borderTopLeftRadius: 0

            }}
        >

            <Grid item container lg={8} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: !masSM ? 16 : 24}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{
                            color: '#fff',
                            fontSize: sCell ? 24 : 32,
                            fontWeight: 600,
                            lineHeight: 1.1,
                            textOverflow: "ellipsis",
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1
                        }}>
                            {escogido.razon}
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                        <Typography sx={{
                            color: '#fff', fontSize: sCell ? 12 : 16, fontWeight: 400, textOverflow: "ellipsis",
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1
                        }}>
                            {`${escogido.ciudad} ${escogido.direccion}`}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>


            <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-end', marginTop: !masSM ? 2 : 24}}>
                {escogido.latitud && escogido.longitud &&
                    <Button
                        onClick={() => irMapa({lat: escogido.latitud, long: escogido.longitud})}
                        color={'secondary'} endIcon={<ArrowForward/>} sx={{color: '#fff'}}>
                        Cómo Llegar
                    </Button>
                }
            </Grid>

        </Grid>
    )

}
export default Tarjeta_Granero    