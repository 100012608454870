/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Email, PhoneAndroid} from "@mui/icons-material";

const Formulario_Mediciones_Agua = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto
                        Icono={AccountCircle} nombre={'Nombre o Razon Social'} dato={'nombre'}   {...props}
                        requerido={'El Nombre o Razon Social son requeridos'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto

                        Icono={Email}
                        nombre={'Correo Electronico'} dato={'correo'} type={'email'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto
                        Icono={PhoneAndroid}
                        nombre={'Celular'} dato={'celular'}
                                  type={'phone'}  {...props}
                        requerido={'El numero celular es requeridos'}
                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Mediciones_Agua